import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/pages/settings.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/pages/profile.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/index.vue')
    },
    {
      path: '/orders#canceled',
      name: 'orders-canceled',
      component: () => import('@/views/orders/index.vue')
    },
    {
      path: '/orders-cash',
      name: 'orders-cash',
      component: () => import('@/views/orders/cash-orders.vue')
    },
    {
      path: '/orders-reseller',
      name: 'orders-reseller',
      component: () => import('@/views/orders/orders-reseller.vue')
    },
    {
      path: '/reports/general',
      name: 'reports|general',
      component: () => import('@/views/reports/general.vue')
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/pages/companies.vue')
    },
    {
      path: '/hotels',
      name: 'hotels',
      component: () => import('@/views/hotels/index.vue')
    },
    {
      path: '/hotels/:id',
      name: 'hotels-',
      component: () => import('@/views/hotels/hotel.vue')
    },
    {
      path: '/resellers',
      name: 'resellers',
      component: () => import('@/views/resellers/index.vue')
    },
    {
      path: '/cashs',
      name: 'cashs',
      component: () => import('@/views/cashs/index.vue')
    },
    {
      path: '/archive/cash',
      name: 'archive|cash',
      component: () => import('@/views/archive/cash.vue')
    },
    {
      path: '/archive/reseller',
      name: 'archive|reseller',
      component: () => import('@/views/archive/reseller.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
